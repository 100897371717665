<script lang="ts">
  import { onDestroy } from "svelte";
  import { navigate } from "svelte-navigator";
  import AreaDropDown from "../components/area_drop_down.svelte";
  import Loading from "../components/loading.svelte";

  import SurveyForm from "../containers/survey_form.svelte";
  import { deepClone } from "../helpers";
  import { addSurvey } from "../services/fetch";
  import { initForm, initUserData } from "../stores/survey";
  import type { TSurveyInput } from "../types";

  let submitted = false;
  let loading = false;
  let surveyForm: typeof initForm = deepClone(initForm);
  let userData: typeof initUserData = deepClone(initUserData);

  onDestroy(() => {
    surveyForm = initForm;
    userData = initUserData;
  });

  const submitForm = async () => {
    loading = true;
    const survey = surveyForm.map((x) => ({
      question: x.question,
      answer: x.selectedValue.includes("Other") ? (x.selectedValue as Array<string>).filter(x => x !== 'Other').concat(x.otherValue) : x.selectedValue,
    }));
    const formData = Object.assign(userData, {
      survey,
    });
    const a = await addSurvey(formData as TSurveyInput);
    submitted = true;
    loading = false;
  };

  const validateEmail = (email) => {
    const re =
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  };

  const validatePhone = (phone) => {
    const re = /(^(\+965\d{8})$)|^(\d{8})$/;
    return re.test(phone);
  };

  $: isFormValid = (): boolean => {
    let formState = true;
    const formLenght = surveyForm.length;
    // if userform has empty data
    if (!userData.email || !userData.phone || !userData.name || !userData.area) {
      return false;
    }
    if (!validateEmail(userData.email)) {
      return false;
    }

    if (userData.name.length < 2) {
      return false;
    }

    if (!validatePhone(userData.phone)) {
      return false;
    }

    for (let i = 0; i < formLenght; i++) {
      const form = surveyForm[i];
      if (form.type === "textarea") {
        if (
          (form.min !== 0 && form.selectedValue.length < form.min) ||
          (form.max !== 0 && form.selectedValue.length > form.max)
        ) {
          return false;
        }
      } else if (form.type === "radio" && form.selectedValue === "") {
        return false;
      } else if (form.type === "checkbox") {
        if (
          form.selectedValue.length < form.min ||
          form.selectedValue.length < form.max
        ) {
          return false;
        }
      }
    }

    return formState;
  };

  const goHome = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const qrCode = urlParams.get("qr");
    navigate(`/?qr=${qrCode}`, {
      replace: true,
    });
  };
</script>

<Loading isLoading={loading} />
{#if !loading}
  <main class=" flex justify-center items-center flex-col h-full">
    <form
      on:submit|preventDefault={submitForm}
      class="bg-gray-superlight flex items-center flex-col p-5 rounded-3xl sm:min-w-[800px]"
    >
      <img src="assets/katch-color.png" alt="katch logo" class="h-40 w-h-40" />

      <div class="overflow-x-scroll bg-white p-5 rounded-3xl mt-5 w-full">
        {#if !submitted}
          <!-- User Data Segment -->
          <div class="mb-5">
            <div class="flex mb-1">
              <p class="font-semibold text-sm p-0 align-text-bottom">Name</p>
            </div>
            <input
              required
              class="w-full resize-none shadow-md rounded-lg p-3 border"
              placeholder="Your Name"
              bind:value={userData.name}
            />
          </div>

          <div class="mb-5">
            <div class="flex mb-1">
              <p class="font-semibold text-sm p-0 align-text-bottom">Phone</p>
            </div>
            <input
              required
              class="w-full resize-none shadow-md rounded-lg p-3 border"
              placeholder="Your phone"
              bind:value={userData.phone}
            />
          </div>

          <div class="mb-5 ">
            <div class="flex mb-1">
              <p class="font-semibold text-sm p-0 align-text-bottom">Email</p>
            </div>
            <input
              required
              class="w-full resize-none shadow-md rounded-lg p-3 border"
              placeholder="Your Email"
              bind:value={userData.email}
            />
          </div>

          <div class="mb-5">
            <div class="flex mb-1">
              <p class="font-semibold text-sm p-0 align-text-bottom">
                Area of Residence
              </p>
            </div>

            <AreaDropDown bind:value={userData.area}/>
          </div>

          <!-- Dynamic Form Data Segment -->
          {#each surveyForm as form, index (form.question)}
            <SurveyForm
              type={form.type}
              question={form.question}
              options={form.options}
              index={index + 1}
              bind:formValue={form.selectedValue}
              min={form.min}
              max={form.max}
              bind:otherValue={form.otherValue}
            />
          {/each}

          <button
            type="submit"
            disabled={!isFormValid()}
            style={`filter: ${isFormValid() ? "none" : "grayscale(100%);"}`}
            class="h-12 w-36 font-bold text-white text-md sm:text-xl"
            >Submit</button
          >
        {:else}
          <div class="flex justify-center items-center flex-col">
            <p style="font-family: kam;" class="text-gray-600 text-xl mb-5">
              Thanks you for submitting the form
            </p>
            <button
              type="button"
              on:click={goHome}
              class="h-12 w-36 font-bold text-white text-md sm:text-xl"
              >Go Home</button
            >
          </div>
        {/if}
      </div>
    </form>
  </main>
{/if}

<style>
  form {
    min-height: 100% ;
  }
  main {
    min-height: 100%;
  }
  button {
    background-image: url("/assets/button-no-text.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    margin: 10px auto 0 auto;
    display: block;
    filter: none;
  }
</style>

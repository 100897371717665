import { registerGifCardMutation, surveyMutation } from "../gql/mutation";
const isDev = false;
const urlLocal = "http://10.13.170.250:3000/";
const urlProd = "https://api.katchkw.com/";
const urlTest = "https://du3qvusyb1.execute-api.us-east-1.amazonaws.com/dev/";
const endpoint = isDev ? urlTest : urlProd;
const fetchOptions = {
    method: "POST",
    headers: {
        "Content-Type": "application/json",
    },
    body: "",
};
export const registerNumber = async (phone, qrCode) => {
    fetchOptions.body = JSON.stringify({ query: registerGifCardMutation, variables: { phone, qrCode } });
    const res = await fetch(endpoint, fetchOptions);
    const { data } = await res.json();
    return data.registerGifCard;
};
export const addSurvey = async (surveyInput) => {
    fetchOptions.body = JSON.stringify({
        query: surveyMutation,
        variables: { surveyInput },
    });
    const res = await fetch(endpoint, fetchOptions);
    const { data } = await res.json();
    return data.addSurvey;
};

<script lang="ts">
	import { onDestroy, onMount } from 'svelte';
	import { fly } from 'svelte/transition';
	import type { TToast } from '../stores/toast';
	import { clearToast } from '../stores/toast';

	let timer: ReturnType<typeof setTimeout>;
	export let toast: TToast;
	onMount(() => {
		timer = setTimeout(() => {
			clearToast(toast.id);
		}, 2500);
	});

	onDestroy(() => {
		clearTimeout(timer);
		clearToast(toast.id);
	});
</script>

<div
	in:fly={{ x: 200 }}
	out:fly={{ x: 200 }}
	class="bg-white ml-2 py-2 px-5 text-brand-green rounded-full text-sm flex items-center justify-center"
>
	{toast.message}
</div>

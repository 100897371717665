<script lang="ts">
  import { Router, Route } from "svelte-navigator";
  import Home from "../routes/home.svelte";
  import Survey from "../routes/survey.svelte";
  import Register from "../routes/register.svelte";
  import Redeem from "../routes/redeem.svelte";
  import Screen404 from "../routes/screen404.svelte";
  import MainLayout from "../Layout/main.svelte";
  import Tailwind from "./tailwind.svelte";
</script>

<Tailwind />
<MainLayout>
  <Router >
    <Route path="/" component={Home} />
    <Route path="/survey" component={Survey} />
    <Route path="/register" component={Register} />
    <Route path="/redeem" component={Redeem} />
    <Route path="/404" component={Screen404} />
    <Route component={Screen404} />
  </Router>
</MainLayout>

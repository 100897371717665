<script lang="ts">
  import { onMount } from "svelte";
  import { useLocation, navigate } from "svelte-navigator";

  let qrCode = "";
  let loading = false;
  const location = useLocation();

  onMount(() => {
    const urlParams = new URLSearchParams(window.location.search);
    qrCode = urlParams.get("qr");
    if (!qrCode) {
      navigate("/404", {
        state: {
          noQrCode: true,
          path: `${$location.pathname}${$location.search}`,
        },
      });
    }
    return () => (loading = false);
  });

  const onSurvey = () => {
    navigate(`/survey?qr=${qrCode}`, { replace: false });
  };

  const onRedeem = () => {
    navigate(`/register?qr=${qrCode}`, { replace: false });
  };
</script>

<main class="flex justify-center items-center flex-col h-full">
  <div
    class="bg-gray-superlight p-5 rounded-3xl max-w-[400px] flex flex-col justify-center items-center relative"
  >
    <img
      src="assets/katch-color.png"
      alt="katch logo"
      class="h-28 w-h-28 mb-2"
    />
    <div class="text-center rounded-3xl p-5 bg-white">
      <h1
        style="font-family: kab;"
        class="text-black font-bold text-lg focus:outline-none"
      >
        REDEEM YOUR <span class="text-brand-red">PROMO CODE</span> to get 50% off
        on your first food order.
      </h1>
      <button class="h-9 w-28 font-bold text-white text-md" on:click={onRedeem}
        >Redeem</button
      >
      <div class="text-gray-400 flex items-center justify-center">
        <hr class="bg-black h-[1.5px] w-[100px] inline-block mx-2"/>
        <span style="font-family: kasb;" class="text-black text-lg">Also</span>
        <hr class="bg-black h-[1.5px] w-[100px] inline-block mx-2"/>
      </div>
      <p class="text-black font-bold text-lg focus:outline-none">
        TAKE A <span class="text-brand-red">SURVEY</span>
        and get a chance to win a brand new
        <span class="text-brand-red"> AppleTV 4K. </span>
      </p>
      <button class="h-9 w-28 font-bold text-white text-md" on:click={onSurvey}
        >Survey</button
      >
    </div>
    <img src="assets/apple-tv.png" alt="katch logo" class="h-36 w-36 mt-2" />
  </div>
</main>

<style>
  button {
    background-image: url("/assets/button-no-text.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    margin: 10px 0 10px 0;
  }
</style>

<script lang="ts">
  export let options: Array<string>;
  export let userSelected = options[0];
</script>

<div>
  <p class="text-gray-400 mb-2 text-sm">Select Any</p>
  {#each options as option}
    <label class="inline-flex items-center mr-5 cursor-pointer">
      <input type="radio" bind:group={userSelected} value={option} />
      <span class="ml-2"> {option} </span>
    </label>
  {/each}
</div>

<script lang="ts">
	import { onDestroy } from 'svelte';
	import { flip } from 'svelte/animate';
	import Toast from '../components/toast.svelte';
	import type { TToast } from '../stores/toast';
	import { clearAll, toastStore } from '../stores/toast';

	let toasts: TToast[] = [{id: "ADEL", message: "ADEL"}];
	const unsubscribe = toastStore.subscribe((t) => {
		toasts = [...t];
	});
	onDestroy(() => {
		clearAll();
		unsubscribe();
	});
</script>

<ul
	class="flex-row-reverse absolute bottom-10 right-0 mr-2 text-gray-300 rounded-full text-sm flex items-center justify-center"
>
	{#each toasts as toast (toast.id)}
		<li animate:flip={{ duration: 500 }}>
			<Toast {toast} />
		</li>
	{/each}
</ul>

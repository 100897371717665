<script lang="ts">
  import { copyToClipboard } from "../services/clipboard";
  import ToastList from "../components/toastList.svelte";
  import type { TPromo, TRegisterGifCardRes } from "../types";
  import { onMount } from "svelte";
  import { useLocation, useNavigate } from "svelte-navigator";
  import { scale } from "svelte/transition";
  import moment from "moment";

  const location = useLocation();
  const navigate = useNavigate();

  const appLinks = {
    ios: "https://apps.apple.com/us/app/katch-kw/id1531865963",
    android: "https://play.google.com/store/apps/details?id=com.aktech.katch",
  };

  let promoInfo: TRegisterGifCardRes | undefined = $location.state?.promo;
  let promo: TPromo;

  onMount(() => {
    if (promoInfo) {
      promo = promoInfo.promo;
    } else {
      navigate("/");
    }
  });

  const createPromoValue = () => {
    const isFlat = promo.type.name === "Flat";
    if (isFlat) {
      return `GET ${promo.type.value} KD ${
        promo.cashback ? "Cash back" : "OFF"
      }`;
    } else {
      return `GET ${promo.type.value}% ${promo.cashback ? "Cash back" : "OFF"}`;
    }
  };

  export const toLocalTime = (date) => {
    try {
      let ISODate;
      const isNotNumber = isNaN(date);
      if (!isNotNumber) {
        ISODate = new Date(parseInt(date)).toISOString();
      } else {
        let parsed = Date.parse(date);
        ISODate = new Date(parseInt(`${parsed}`)).toISOString();
      }
      const utcDate = moment.utc(ISODate).toDate();
      const local = moment(utcDate).local().format("DD/MM/YYYY  HH:mm");
      return local;
    } catch (error) {
      return false;
    }
  };

  const copyCode = async () => {
    await copyToClipboard(promo.code);
  };

  const back = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const qrCode = urlParams.get("qr");

    navigate(`/?qr=${qrCode}`, { replace: true });
  };
</script>

{#if !!promo}
  <div
    in:scale
    class="h-full w-full flex flex-col lg:flex-row justify-center items-center"
  >
    <div
      class="lg:flex-1 w-full h-full max-h-[700px] max-w-[600px] lg:max-w-[none] flex flex-col justify-center items-center"
    >
      <img
        src="assets/katch-white.png"
        alt="katch logo"
        class="h-28 w-28 xl:hidden block mt-6"
      />
      <div
        id="white-bg"
        class="w-full h-full flex flex-col sm:max-h-[1000px] justify-center items-center relative"
      >
        <img
          src="assets/katch-color.png"
          alt="katch logo"
          class="sm:h-44 sm:w-h-44 mb-2 hidden xl:block"
        />
        <h4
          style="font-family: kab;"
          class="bt-1 mb-3 text-xl sm:text-3xl text-black focus:outline-none"
        >
          Awesome!!
        </h4>
        <div
          class="py-1 flex items-center bg-white text-center px-3 rounded-full shadow-md border-2 border-gray-300"
        >
          <p
            style="font-family: kab;"
            class="ml-2 text-base sm:text-xl flex-grow px-2 sm:px-4 "
          >
            {promo.code}
          </p>
          <button
            on:click={copyCode}
            class="w-28 h-10 sm:w-32 sm:h-11 focus:outline-none focus:ring-2 focus:ring-opacity-50"
          />
        </div>
        <div class="flex my-5 justify-items-center items-center">
          <a href={appLinks.ios} target="blank">
            <img
              src="/assets/apple.webp"
              alt="apple"
              class="h-9 sm:h-12 w-auto mx-auto"
            />
          </a>
          <a href={appLinks.android} target="blank">
            <img
              src="/assets/android.webp"
              alt="android"
              class="ml-2 h-9 sm:h-12 w-auto mx-auto"
            />
          </a>
        </div>
        <button id="home-button" class="h-9 w-9" on:click={back} />
      </div>
    </div>

    <!-- Steps -->
    <div class="flex-1 flex">
      <div class="px-3 py-5 mx-3">
        <h1
          style="font-family: kah;"
          class="focus:outline-none steps-text font-bold text-4xl md:text-6xl lg:text-7xl mb-7"
        >
          Steps
        </h1>
        <ul class="text-2xl md:text-3xl lg:text-4xl">
          <li class="mt-3">
            <p style="font-family: kaeb;" class="steps-text">
              1 - Download the app
            </p>
          </li>
          <li class="mt-3">
            <p style="font-family: kaeb;" class="steps-text">
              2 - Pick food that fits your mood
            </p>
          </li>
          <li class="mt-3">
            <p style="font-family: kaeb;" class="steps-text">
              3 - Use code at check out and {createPromoValue()}
            </p>
          </li>
        </ul>
      </div>
    </div>
    <ToastList />
  </div>
{/if}

<style>
  #white-bg {
    background-image: url("/assets/white-bg.jpg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    position: relative;
  }

  button {
    background-image: url("/assets/copy-code.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }

  #home-button {
    background-image: url("/assets/home.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }

  .steps-text {
    -webkit-text-stroke: 1px lightslategray;
    -webkit-text-fill-color: white;
    color: #fff;
  }

  @media (min-width: 1280px) {
    #white-bg::after {
      display: none;
    }
  }
</style>

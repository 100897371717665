<script lang="ts">
  import { fade } from "svelte/transition";
  export let isLoading = false;

  const colors = [
    "bg-brand-red",
    "bg-brand-green",
    "bg-brand-blue",
    "bg-brand-yellow",
  ];
  const defaultStyle = "p-2  w-4 h-4 rounded-full animate-bounce";
</script>

{#if isLoading}
  <div
    id="loading-container"
    transition:fade
    class="absolute top-0 bottom-0 left-0 right-0 flex justify-center items-center flex-col"
  >
    <div
      class="flex space-x-2 rounded-full justify-center items-center"
    >
      {#each colors as color, i (color)}
        <div
          style={`animation-delay: 0.${i + 1}s;`}
          class={`bg-white ${defaultStyle}`}
        />
      {/each}
    </div>
    <div
      class="bg-white w-[88px] h-[45px] mt-1 rounded-bl-full rounded-br-full"
    />
  </div>
{/if}

<style>
  #loading-container {
    background-image: url("/assets/green-bg.jpg");
    background-repeat: no-repeat;
    background-size: cover;
  }
  </style>
  
<script lang="ts">
  import { registerNumber } from "../services/fetch";
  import { navigate, useLocation } from "svelte-navigator";
  import Loading from "../components/loading.svelte";
  import { onMount } from "svelte";
  import { fade } from "svelte/transition";

  let phone = "";
  let loading = false;
  let reqErr: any = null;
  let fieldError = "";
  let qrCode = "";
  const location = useLocation();

  onMount(() => {
    const urlParams = new URLSearchParams(window.location.search);
    qrCode = urlParams.get("qr");
    if (!qrCode) {
      navigate("/404", {
        state: {
          noQrCode: true,
          path: `${$location.pathname}${$location.search}`,
        },
      });
    }
    return () => (loading = false);
  });

  const onChange = (e: Event): void => {
    const event = e as any;
    const value = event.target.value.trim();
    const test = /^[\+\d]+$/gm.test(value);
    if (test) {
      phone = value;
    } else {
      phone = phone.slice(0, phone.length - 1);
    }
  };

  const submit = async (): Promise<void> => {
    fieldError = "";
    loading = true;
    reqErr = null;
    const phoneRegex = new RegExp(/^[\+0-9]{8,12}$/);
    if (!phoneRegex.test(phone)) {
      fieldError = "Invaild phone number";
      loading = false;
    } else {
      try {
        const data = await registerNumber(phone.replace("+965", ""), qrCode);
        if (data) {
          const error = data.error;

          if (error) {
            if (error.field) {
              fieldError = error.message;
            }

            if (error.code && error.code === 404) {
              navigate("/404", { state: { invaildQrCode: true } });
            }
          }

          if (data.promo) {
            phone = "";
            reqErr = null;
            fieldError = "";
            const urlParams = new URLSearchParams(window.location.search);
            const qrCode = urlParams.get("qr");
            navigate(`/redeem?qr=${qrCode}`, { state: { promo: data } });
          }
        }
      } catch (err) {
        reqErr = "Oops we ran into a issues and can't process your request";
        loading = false;
      }
    }
  };
</script>

<Loading isLoading={loading} />
{#if !loading}
  <div transition:fade class="h-full w-full flex justify-center items-center">
    <div
      id="white-bg"
      class="w-full h-full max-h-[500px] sm:max-h-[none] flex flex-col justify-center items-center relative"
    >
      <img
        src="assets/katch-color.png"
        alt="katch logo"
        class="sm:h-44 sm:w-h-44 mb-2 hidden sm:block"
      />
      <h4
        style="font-family: kab;"
        class="text-xl sm:text-2xl text-black focus:outline-none"
      >
        Enter your phone number
      </h4>
      <p style="font-family: kam;" class="mt-1 text-gray-500">
        & redeem your gift code
      </p>
      <form
        on:submit|preventDefault={submit}
        class="flex flex-col justify-center items-center mt-3 sm:mt-6"
      >
        <input
          style="font-family: kasb;"
          placeholder="Phone number"
          class="w-64 h-10 sm:h-11 sm:w-72 text-center font-medium px-3 rounded-full text-xl shadow-md border-2 border-gray-400 focus:outline-none focus:ring-2 focus:ring-brand-green focus:border-transparent"
          bind:value={phone}
          on:input={onChange}
          minlength="8"
          maxlength="12"
        />
        <button
          class="w-36 h-10 sm:w-40 sm:h-11  px-5 mt-5 focus:outline-none focus:ring-2 focus:ring-opacity-50"
        />
      </form>
      {#if fieldError || reqErr}
        <p class="text-brand-red text-md mt-3 h-1">
          {fieldError ? fieldError : ""}
          {reqErr ? reqErr : ""}
        </p>
      {/if}
    </div>
  </div>
{/if}

<style>
  #white-bg {
    background-image: url("/assets/white-bg.jpg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    position: relative;
  }

  #white-bg::after {
    background-image: url("/assets/katch-white.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    position: absolute;
    bottom: 480px;
    content: "";
    height: 120px;
    width: 120px;
    margin-right: 10px;
  }

  button {
    background-image: url("/assets/redeem-code.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }

  @media (min-width: 640px) {
    #white-bg::after {
      display: none;
    }
  }
</style>

import { writable } from 'svelte/store';
import { uuid } from 'short-uuid';
export const toastStore = writable([]);
export const addToast = ({ message }) => {
    toastStore.update((t) => {
        let list = t;
        if (list.length >= 3) {
            list = list.splice(0, 2);
        }
        const newtoast = {
            id: uuid(),
            message
        };
        return [newtoast, ...list];
    });
};
export const clearToast = (id) => {
    toastStore.update((t) => {
        const filtered = t.filter((x) => x.id !== id);
        return filtered;
    });
};
export const clearAll = () => {
    toastStore.update(() => []);
};

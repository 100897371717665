import { addToast } from "../stores/toast";
export const copyToClipboard = async (value) => {
    try {
        // navigator clipboard api needs a secure context (https)
        if (navigator.clipboard && window.isSecureContext) {
            // navigator clipboard api method'
            addToast({ message: "COPIED" });
            await navigator.clipboard.writeText(value);
        }
        else {
            const textArea = document.createElement("textarea");
            textArea.value = value;
            textArea.style.position = "absolute";
            textArea.style.opacity = "0";
            document.body.appendChild(textArea);
            textArea.focus();
            textArea.select();
            return new Promise((res, rej) => {
                const copied = document.execCommand("copy");
                if (copied) {
                    addToast({ message: "COPIED" });
                    res(true);
                }
                else {
                    addToast({ message: "FAILED TO COPY" });
                    rej(false);
                }
                textArea.remove();
            });
        }
    }
    catch (error) {
        addToast({ message: "FAILED TO COPY" });
        return false;
    }
};

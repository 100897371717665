<script lang="ts">
  export let options: Array<string>;
  export let selection: any[];
  export let max: number;
  export let min: number;
  export let otherValue: string;
  
  $: isDisabled = () => {
    if(max === 0) return false;
    return selection.length >= max;
  };
  const minMaxLable = (): string => {
    if (max === 0) {
      if (min === 0) {
        return "Select Any";
      } else {
        return `Select Atleast ${min}`;
      }
    } else if (max > 0) {
      if(min === 0){
        return `Select upto ${max}`;
      }
      return `Select Any Between ${min} and ${max}`;
    }
  };
</script>

<div class="flex flex-col">
  <p class="text-gray-400 mb-2 text-sm">{minMaxLable()}</p>

  {#each options as option}
    <label class="mb-2 cursor-pointer">
      <input
        type="checkbox"
        bind:group={selection}
        value={option}
        disabled={isDisabled() && !selection.includes(option)}
      />
      {option}
    </label>
    {#if option === 'Other' && selection.includes('Other')}
    <input required class="w-full resize-none shadow-md rounded-lg p-3 border h-20" placeholder="Type here........"
    bind:value={otherValue}
    >
    {/if}
  {/each}
</div>

export const initUserData = {
    name: "",
    email: "",
    phone: "",
    area: "",
};
export const initForm = [
    {
        question: "Whats your favorite Cuisine ?",
        type: "checkbox",
        options: ["Arabic", "Indian", "Chinese", "Filipino", "Other"],
        min: 1,
        max: 0,
        selectedValue: [],
        otherValue: "",
    },
    {
        question: "How often do you order food online ?",
        type: "radio",
        options: ["Once a month", "More than 5", "Under 5 times", "Never"],
        min: 1,
        max: 1,
        selectedValue: "",
    },
    {
        question: "What is/are your favorite restaurants in kuwait ? (Not Fast Food)",
        type: "textarea",
        options: [],
        min: 0,
        max: 0,
        selectedValue: "",
    },
    {
        question: "What are the most famous restaurants in your neighborhood ?",
        type: "textarea",
        options: [],
        min: 0,
        max: 0,
        selectedValue: "",
    },
    {
        question: "What factors did you like most about using food delivery app ?",
        type: "checkbox",
        options: [
            "Delivery speed",
            "Food quality",
            "Overall price",
            "Reviews and recommendations",
            "Customer service",
        ],
        min: 1,
        max: 0,
        selectedValue: "",
    },
    {
        question: "Do you mind the item price if you find a new and exciting dish ?",
        type: "radio",
        options: ["Yes", "No", "Depends on the item"],
        min: 1,
        max: 1,
        selectedValue: "",
    },
    {
        question: "Im a ........... ?",
        type: "radio",
        options: ["Kuwaiti", "Expat"],
        min: 1,
        max: 1,
        selectedValue: "",
    },
    {
        question: "What Age Group do you belong to ?",
        type: "radio",
        options: ["15 - 20", "20 - 25", "25 - 30", "30 - 40", "40 - 50", "Other"],
        min: 1,
        max: 1,
        selectedValue: "",
    },
    {
        question: "Do you wish to receive amazing offers and awesome new food options from us ?",
        type: "radio",
        options: ["Yes", "No"],
        min: 1,
        max: 1,
        selectedValue: "",
    },
];

<script lang="ts">
import Radio from "../components/radio.svelte";
import Checkbox from "../components/checkbox.svelte";
export let formValue: any;
export let type: string;
export let question: string;
export let options: any;
export let index: number;
export let min: number;
export let max: number;
export let otherValue: string;


</script>

<div>
    <div class="flex {index === 1 ? '' : 'mt-5'} mb-2">
        <p class="font-semibold text-sm p-0 align-text-bottom">Q{index}: <span class="font-semibold text-md">{question}</span></p>
    </div>
    {#if type === "textarea"}
    <p class="text-gray-400 mb-2 text-sm">
        {min > 0 ? `Text must be at least ${min} char long.`: ''}
        {max > 0 ? `Max ${max}`: ''}
    </p>
    <textarea class="w-full resize-none shadow-md rounded-lg p-3 border h-40" placeholder="Type here........"
        bind:value={formValue}
        ></textarea>
    {:else if type === "radio"}
    <Radio {options} bind:userSelected={formValue} ></Radio>
    {:else if type === "checkbox"}
    <Checkbox bind:selection={formValue} options={options} bind:otherValue {min} {max}></Checkbox>
    {/if}
</div>

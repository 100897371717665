<script lang="ts">
  import Select from "svelte-select";
  import { kuwaitAreas } from "../stores/kuwait_area";

  export let value = null;

  function handleSelect(event) {
    value = event.detail.value;
  }
</script>

<Select  items={kuwaitAreas} value={null} on:select={handleSelect} />

export const registerGifCardMutation = `
 mutation RegisterGifCard($phone: String, $qrCode: String){
  registerGifCard(phone: $phone, qrCode: $qrCode){
        isNew
        promo {
          _id
          cashback
          code
          restApplicable
          remainNumOfApplies
          perUserApply
          respawnTime
          name
          description
          restricted
          hidden
          active
          expiry
          start
          terms
          type {
            name
            minOrder
            maxLimit
            value
          }
        }
        error
    }
 }
`;
export const surveyMutation = `
mutation addSurvey($surveyInput: SurveyInput){
  addSurvey(SurveyInput: $surveyInput)
}
`;

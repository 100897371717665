<script lang="ts">
    import { onMount } from "svelte";
    import { useLocation, useNavigate } from "svelte-navigator";
    import { scale } from "svelte/transition";
  
    const location = useLocation();
    const navigate = useNavigate();
  
    
  
    let mounted: boolean = false
    let errorTitle = 'You Seem Lost';
    let errorMessage = "Yeah, we're as confused as you are, you took the wrong turn and came here.";
    let isLost = true;
    let route: string = $location.state?.path;

    onMount(() => {
        mounted = true;
        const invaildQrCode: boolean = $location.state?.invaildQrCode;
        const noQrCode: boolean = $location.state?.noQrCode;
        
        if(noQrCode){
          errorTitle = 'Oh Oops!';
          errorMessage = "Yeah, we're as confused as you are. Did you scan your gift card ?";
        }
        
        if(invaildQrCode){
          isLost = false;
          errorTitle = 'Oh Oops!';
          errorMessage = "It seems like the QR Code you scanned was not found or is invaild.\nGo home and give it another shot.";
        }
    });   
  
    const back = () => {
        let to: string | number = -1;
        if(route){
            to = route;
        }
        navigate(to as string)
    };
  </script>
  
  {#if mounted}
    <div 
    in:scale
    class="h-full w-full flex justify-center items-center">
        <div 
        id="white-bg"
        class="w-full h-full max-h-[500px] sm:max-h-[none] flex flex-col justify-center items-center relative"
        >
        <img src="assets/katch-color.png" alt="katch logo" class="sm:h-44 sm:w-h-44 mb-2 hidden sm:block"/>
        <h4 style="font-family: kab;" class="bt-1 mb-3 font-bold text-xl sm:text-3xl text-brand-red focus:outline-none">
            {errorTitle}
        </h4>
        <p style="font-family: kasb;" class="text-center mx-36">{errorMessage}</p>
        {#if !isLost}
            <button id="home-button" class="h-9 w-9 mt-5" on:click={back}/>  
        {/if}
        </div>
    </div>
  {/if}
    

  
  
  <style>
    #white-bg {
      background-image: url("/assets/white-bg.jpg");
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
      position: relative;
    }
  
    #white-bg::after {
      background-image: url("/assets/katch-white.png");
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
      position: absolute;
      bottom: 480px;
      content: '';
      height: 120px;
      width: 120px;
      margin-right: 10px;
    }
  
    
  
    #home-button {
      background-image: url("/assets/home.png");
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
    }
  
    @media (min-width: 640px) {
      #white-bg::after {
          display: none;
      }
    }
</style>
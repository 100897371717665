<script lang="ts">
  const year = new Date().getFullYear();
</script>

<main id="main-bg" class="h-full flex flex-col justify-center items-center min-h-screen overflow-scroll">
  <div  class="h-full w-full"><slot /></div>
  <div class="flex-1">
    <span class="text-sm text-white mx-auto block text-center py-2">
      &copy; {year}
    </span>
  </div>
</main>

<style>
  #main-bg {
    background-image: url("/assets/green-bg.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 100vh;
  }
</style>

export const kuwaitAreas = [
    "Abdullah Al-Salem",
    "Adailiya",
    "Bneid Al Qar",
    "Daiya",
    "Dasma",
    "Dasman",
    "Doha",
    "Faiha",
    "Ghornata",
    "Jaber Al Ahmad",
    "Kaifan",
    "Khaldiya",
    "Kuwait City",
    "Mansouriya",
    "Mirqab",
    "Mubarakiya Camps",
    "Nahda",
    "North West Sulaibikhat",
    "Nuzha",
    "Qadsiya",
    "Qibla",
    "Qortuba",
    "Rawda",
    "Salhiya",
    "Shamiya",
    "Sharq",
    "Shuwaikh Administrative",
    "Shuwaikh Educational",
    "Shuwaikh Industrial 1",
    "Shuwaikh Industrial 2",
    "Shuwaikh Industrial 3",
    "Shuwaikh Medical",
    "Shuwaikh Residential",
    "Sulaibikhat",
    "Surra",
    "Yarmouk",
    "Al-Bedae",
    "Bayan",
    "Hawally",
    "Hitteen",
    "Jabriya",
    "Maidan Hawally",
    "Ministries Zone",
    "Mishrif",
    "Mubarak Al-Abdullah - West Mishref",
    "Rumaithiya",
    "Salam",
    "Salmiya",
    "Salwa",
    "Shaab",
    "Shuhada",
    "Siddiq",
    "Zahra",
    "Abu Ftaira",
    "Abu Hasaniya",
    "Adan",
    "Al Masayel",
    "Al-Qurain",
    "Al-Qusour",
    "Fnaitess",
    "Messila",
    "Mubarak Al-Kabir",
    "Sabah Al-Salem",
    "Sabhan Industrial",
    "South Wista",
    "West Abu Fetera Small Indust",
    "Wista",
    "Abu Halifa",
    "Al-Ahmadi",
    "Ali Sabah Al-Salem - Umm Al Hayman",
    "Al-Julaia'a",
    "Bnaider",
    "Dhaher",
    "Egaila",
    "Fahad Al Ahmed",
    "Fahaheel",
    "Fintas",
    "Hadiya",
    "Jaber Al Ali",
    "Khairan",
    "Magwa",
    "Mahboula",
    "Mangaf",
    "Mina Abdullah",
    "Nuwaiseeb",
    "Riqqa",
    "Sabah Al Ahmad 1",
    "Sabah Al Ahmad 2",
    "Sabah Al Ahmad 3",
    "Sabah Al Ahmad 4",
    "Sabah Al Ahmad 5",
    "Sabah Al Ahmad Marine City",
    "Sabahiya",
    "Shuaiba Port",
    "South Sabahiya",
    "Talabat Island",
    "Wafra farms",
    "Wafra residential",
    "Zour",
    "Abdullah Al-Mubarak - West Jeleeb",
    "Airport",
    "Andalous",
    "Ardhiya",
    "Ardhiya 4",
    "Ardiya Small Industrial",
    "Ardiya Storage Zone",
    "Ashbeliah",
    "Dhajeej",
    "Farwaniya",
    "Ferdous",
    "Jeleeb Al-Shuyoukh",
    "Khaitan",
    "Omariya",
    "Rabiya",
    "Rai",
    "Reggai",
    "Rehab",
    "Sabah Al-Nasser",
    "Sheikh Saad Al Abdullah Airport",
    "Jahra - Al Naeem",
    "Jahra - Amgarah Industrial",
    "Jahra - Jahra Area",
    "Jahra - Kabd",
    "Jahra - Nasseem",
    "Jahra - Oyoun",
    "Jahra - Qasr",
    "Jahra - Saad Al Abdullah",
    "Jahra - Sulaibiya",
    "Jahra - Sulaibiya Industrial 1",
    "Jahra - Sulaibiya Industrial 2",
    "Jahra - Sulaibiya Residential",
    "Jahra - Taima",
    "Jahra - Waha",
    "Jahra Qairawan - South Doha",
];
